import config from "config";
import {
  authGetJson,
  authPostJson,
  authDeleteJson,
  authPostFormData,
} from "utilities/msalFetches";
import { throwIfResponseError } from "apis/apiUtilities";
import {
  formatUploadAttachmentResponse,
  formatCreateSWRequest,
  formatUpdateSWResponse,
  formatUpdateSWRequest,
  formatGetSWList,
  formatGetSWResponse,
  formatSearchSWRequest,
  formatUnlockSWCurrentEditorResponse,
  formatUnlockSWCurrentEditorRequest,
  formatGetSWCommentsResponse,
  formatGetSWUserFeedbacksResponse,
  formatStepInfo,
  formatGetSWStepCommentsResponse,
  formatGetAllSWCommentsResponse,
  formatGetRCMappingResponse,
  formatGetSWRevisionHistoriesResponse,
  formatDownloadZipArchive,
  formatArchiveResponse,
  formatGetRCSWResponse,
  formatBulkDeleteSWDraft,
  formatTIMESearchSWRequest,
  formatTimeImageResponse,
  formatGetTimeMediaFilesMappingResponse,
  formatTimeRCRequestDataResponse,
  formatGetTimeRCProcessingResponse,
  formatGetConfidentialEnabledUsersResponse,
  formatGetSiteLocationsResponse,
  formatGetConnectedSWIsResponse,
  formatGetConnectedSWIsByChildGuidResponse,
  formatUploadConnectedSWIsResponse,
  formatGetSWNoticeSummaryResponse, 
  formatCopySWAttachmentsToSWDraft
} from "./swFormatters";
import {
  IUploadSWAttachmentResponse,
  ISW,
  SWSortFields,
  IUnlockSWCurrentEditor,
  IComment,
  ISWUserFeedbackDetails,
  ISWUserFeedbackAck,
  IStepComment,
  IStepsCommentInfo,
  IAllSWComments,
  IGetRCMapping,
  ISWRevisionHistory,
  IArchiveResponse,
  IGetRCSW,
  IGetTimeMediaFileMapping,
  ITimeRCProcessing,
  SWTypes,
  ISiteLocationsData,
  IConnectedSWIData,
  IAddConnectedSWIPayload,
  IDeleteConnectedSWIPayload,
  IUploadConnectedSWIsResponse,
  ISWNoticeSummary, 
  ICopiedSWAttachment
} from "interfaces/sw/SWInterfaces";
import { ISWFilterFields, IZipList, ISWListData, ITimeRCRequestData, IConnectedSWArchivalDataToSave } from "store/swList/swListTypes";
import { SortDirection } from "interfaces/misc/miscInterfaces";
import { ICopySWAttachmentsToSWDraft, ITimeImageData } from "store/manageSW/manageSWTypes";

import {
  ConfidentialAccessRoles,
  IConfidentialSWUser,
} from "interfaces/user/UserInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

export interface ICreateSWDraftParams {
  guid: string;
  version: number | null;
  duplicate: boolean;
  swType: SWTypes;
  newOwningOrgId: string | undefined;
  newOwningPlantId: string | undefined;
  staleReusableContentAction?: "Delink" | "Update";
  updateGemsRevision: boolean | undefined;
}

export interface IPDFDownloadResponse {
  blob: Blob;
  filename: string;
}

class SWApi {
  public async createSW(sw: ISW): Promise<string> {
    const response = await authPostJson(
      config.endpoints.sw.createSW,
      JSON.stringify(formatCreateSWRequest(sw))
    );

    await throwIfResponseError(response);

    return (await response.json()).SWGuid;
  }

  public async uploadSWAttachment(
    swGuid: string,
    swVersion: number,
    file: File
  ): Promise<IUploadSWAttachmentResponse> {
    let formData = new FormData();
    formData.append("File", file);

    let response = await authPostFormData(
      config.endpoints.sw.uploadAttachment
        .replace("{guid}", swGuid)
        .replace("{version}", swVersion.toString()),
      formData
    );

    await throwIfResponseError(response);

    return formatUploadAttachmentResponse(await response.json());
  }

  public async getTimeImage(searchTerm: string): Promise<ITimeImageData> {
    const response = await authPostJson(config.endpoints.sw.searchTimeImage,
      JSON.stringify({
        SearchTerm: searchTerm,
      }));

    await throwIfResponseError(response);
    return formatTimeImageResponse(await response.json());
  }

  public async getBlob(searchTerm: string): Promise<Blob> {
    const response = await fetch(searchTerm);

    await throwIfResponseError(response);
    return response.blob();
  }

  public async uploadTimeImage(swguid: string,
    swversion: number,
    filename: string,
    link: string,
    imageNumber: string): Promise<IUploadSWAttachmentResponse> {
    const response = await authPostJson(config.endpoints.sw.uploadTimeImage,
      JSON.stringify({
        SWGuid: swguid,
        SWVersion: swversion,
        Filename: filename,
        ImageURL: link,
        ImageNumber: imageNumber,
      }));

    await throwIfResponseError(response);
    return formatUploadAttachmentResponse(await response.json());
  }

  public async getAttachmentUrl(
    swGuid: string,
    swVersion: number,
    filename: string
  ): Promise<string> {
    const response = await authPostJson(
      config.endpoints.sw.generateBlobUri
        .replace("{guid}", swGuid)
        .replace("{version}", swVersion.toString()),
      JSON.stringify({
        Filename: filename,
      })
    );

    await throwIfResponseError(response);

    return (await response.json()).AttachmentUrl;
    }

    public async getSWBlobURL(
        swGuid: string,
        swVersion: number,
        filename: string
    ): Promise<string> {
        const response = await authPostJson(
            config.endpoints.sw.getBlobResources
                .replace("{guid}", swGuid)
                .replace("{version}", swVersion.toString()),
            JSON.stringify({
                Filename: filename,
            })
        );

        await throwIfResponseError(response);
        const blobData = await response.blob();
        const blobUrl = window.URL.createObjectURL(blobData);
        return blobUrl;
    }

  public async updateSW(sw: ISW): Promise<ISW> {
    const response = await authPostJson(
      config.endpoints.sw.updateSW
        .replace("{guid}", sw.guid)
        .replace("{version}", "0"),
      JSON.stringify(formatUpdateSWRequest(sw))
    );

    await throwIfResponseError(response);

    return formatUpdateSWResponse(await response.json());
  }

  public async unlockSWCurrentEditorLock(
    swGuid: string,
    owningOrgId?: string,
    owningPlantId?: string
  ): Promise<IUnlockSWCurrentEditor> {
    const response = await authPostJson(
      config.endpoints.sw.unlockSWCurrentEditor.replace("{guid}", swGuid),
      JSON.stringify(formatUnlockSWCurrentEditorRequest(swGuid, owningOrgId, owningPlantId))
    );

    await throwIfResponseError(response);

    return formatUnlockSWCurrentEditorResponse(await response.json());
  }

  public async getSWList(
    filterFields: ISWFilterFields,
    excludeDrafts: boolean,
    limitResults: boolean,
    sortBy: SWSortFields,
    sortDir: SortDirection,
    newIndex: number,
    isSearchforRC: boolean,
    isSearchforRCNotice: boolean,
    searchSource: string
  ): Promise<ISWListData> {
    const response = await authPostJson(
      config.endpoints.sw.searchSW,
      JSON.stringify(
        formatSearchSWRequest(
          filterFields,
          excludeDrafts,
          limitResults,
          sortBy,
          sortDir,
          newIndex,
          isSearchforRC,
          isSearchforRCNotice,
          searchSource
        )
      )
    );

    await throwIfResponseError(response);

    let json = await response.json();
    let searchResults = formatGetSWList(json);
    return searchResults;
  }

  public async getTIMESWList(
    filterFields: ISWFilterFields
  ): Promise<ISWListData> {
    const response = await authPostJson(
      config.endpoints.sw.searchTIMESW,
      JSON.stringify(
        formatTIMESearchSWRequest(
          filterFields.searchText
        )
      )
    );

    await throwIfResponseError(response);

    let json = await response.json();
    let searchResults = formatGetSWList(json);
    return searchResults;
  }

  public async getSW(guid: string, version: number): Promise<ISW> {
    let endpoint = config.endpoints.sw.getSW
      .replace("{guid}", guid)
      .replace("{version}", version.toString());
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();
    let sw = formatGetSWResponse(json);

    return sw;
  }

  public async getSiteLocations(): Promise<ISiteLocationsData> {
    let endpoint = config.endpoints.sw.getSiteLocations;
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();

    return formatGetSiteLocationsResponse(json);
  }

  public async getTimeRCSW(uid: string, swGuid: string, swType: string, isRCTask: string, rcType: string, stepGuid?: string): Promise<ITimeRCRequestData> {
    let endpoint = config.endpoints.sw.getTimeRCSW
      .replace("{uid}", uid)
      .replace("{swGuid}", swGuid)
      .replace("{swType}", swType)
      .replace("{stepGuid}", stepGuid ? stepGuid : "")
      .replace("{isRCTask}", isRCTask)
      .replace("{rcType}", rcType);
    let response = await authGetJson(endpoint);
    await throwIfResponseError(response);

    let json = await response.json();
    let timeRCRequestData = formatTimeRCRequestDataResponse(json);

    return timeRCRequestData;
  }

  public async copyRCAttachments(rcGuid: string, rcVersion: number, swGuid: string): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.sw.copyRCAttachments
        .replace("{swGuid}", swGuid)
        .replace("{rcGuid}", rcGuid)
        .replace("{rcVersion}", rcVersion.toString()),
      null
    );

    await throwIfResponseError(response);

    return true;

  }

  public async copySWAttachmentsToRC(swGuid: string, swVersion: number, rcGuid: string): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.sw.copySWToRCAttachments
        .replace("{swGuid}", swGuid)
        .replace("{swVersion}", swVersion.toString())
        .replace("{rcGuid}", rcGuid),
      null
    );

    await throwIfResponseError(response);

    return true;

  }

  public async breakRCLink(swID: number, rcID: number): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.sw.breakRCLink
        .replace("{swID}", swID.toString())
        .replace("{rcID}", rcID.toString())
      ,
      null
    );

    await throwIfResponseError(response);

    return true;
  }

  public async publishRC(guid: string, version: number): Promise<ISW> {
    let response = await authPostJson(
      config.endpoints.sw.publishRC
        .replace("{swGuid}", guid)
        .replace("{swVersion}", version.toString()),
      null
    );
    await throwIfResponseError(response);
    let json = await response.json();
    let sw = formatGetSWResponse(json);

    return sw;
  }

  public async createSWDraft(params: ICreateSWDraftParams): Promise<string> {
    const {
      guid,
      version,
      duplicate,
      staleReusableContentAction,
      swType,
      newOwningOrgId,
      newOwningPlantId,
      updateGemsRevision,
    } = params;

    let url =
      version === null
        ? config.endpoints.sw.createSWDraft.replace("{guid}", guid)
        : config.endpoints.sw.createSWDraftFromVersion
          .replace("{guid}", guid)
          .replace("{version}", version.toString());

    url += `?duplicate=${duplicate}`;

    if (staleReusableContentAction) {
      url += `&staleReusableContentAction=${staleReusableContentAction}`;
    }

    if (swType) {
      url += `&newSwType=${swType}`;
    }

    if (newOwningOrgId) {
      url += `&newOwningOrgId=${newOwningOrgId}`;
    }

    if (newOwningPlantId) {
      url += `&newOwningPlantId=${newOwningPlantId}`;
    }

    if (updateGemsRevision) {
      url += `&updateGemsRevision=true`;
    }

    let response = await authPostJson(url, JSON.stringify({}));

    await throwIfResponseError(response);

    return (await response.json()).SWGuid;
  }

  public async ArchiveConnectedSW(params: IConnectedSWArchivalDataToSave): Promise<string> {
    let url = config.endpoints.sw.archiveconnectedsw
          .replace("{guid}", params.swGuid)
          .replace("{version}", params.swVersion.toString());

    if (params.newOwningPlantId) {
      url += `?newOwningPlantId=${params.newOwningPlantId}`;
    }    
    if (params.newSwMaterial) {
      url += `&newSwMaterial=${params.newSwMaterial}`;
    }
    if (params.newSWGroup) {
      url += `&newSWGroup=${params.newSWGroup}`;
    }
    if (params.newSWGroupCounter) {
      url += `&newSWGroupCounter=${params.newSWGroupCounter}`;
    }
    if (params.connectedSWId) {
      url += `&connectedSWId=${params.connectedSWId}`;
    }
    let response = await authPostJson(url, JSON.stringify({}));

    await throwIfResponseError(response);

    return (await response.json()).SWGuid;
  }


  public async createLCL(
    guid: string,
    version: number
  ): Promise<string> {
    const url = config.endpoints.sw.createLCL
      .replace("{guid}", guid)
      .replace("{version}", version.toString());

    let response = await authPostJson(url, JSON.stringify({}));

    await throwIfResponseError(response);

    return (await response.json()).SWGuid;
  }

  public async archiveSW(guid: string): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.sw.archiveSW.replace("{guid}", guid),
      JSON.stringify({})
    );

    await throwIfResponseError(response);

    return true;
  }

  public async saveSWComment(
    guid: string,
    version: number,
    comment: string
  ): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.sw.saveSWComment
        .replace("{guid}", guid)
        .replace("{version}", version.toString()),
      JSON.stringify({
        SWGuid: guid,
        Comment: comment,
      })
    );

    await throwIfResponseError(response);

    return true;
  }

  public async resolveStepComment(commentID: number, stepID: number, swID: number): Promise<IStepsCommentInfo> {
    const response = await authPostJson(
      config.endpoints.sw.resolveStepComment
        .replace("{commentID}", commentID.toString()),
      JSON.stringify({
        stepID: stepID,
        commentID: commentID,
        SWID: swID,
      }));

    await throwIfResponseError(response);
    let json = await response.json();
    let stepInfo = formatStepInfo(json);

    return stepInfo;
  }

  public async saveSWStepComment(stepID: number, comment: string, swID: number): Promise<IStepsCommentInfo> {
    let response = await authPostJson(config
      .endpoints
      .sw
      .saveSWStepComment
      .replace("{stepID}", stepID.toString())
      ,
      JSON.stringify({
        StepID: stepID,
        Comment: comment,
        SWID: swID,
      }));

    await throwIfResponseError(response);
    let json = await response.json();
    let stepInfo = formatStepInfo(json);

    return stepInfo;
  }

  public async getSWComments(guid: string, version: number): Promise<IComment[]> {
    let response = await authPostJson(config
      .endpoints
      .sw
      .getSWComments
      .replace("{guid}", guid)
      .replace("{version}", version.toString()),
      JSON.stringify({
        SWGuid: guid,
        SWVersion: version,
      })
    );

    await throwIfResponseError(response);

    let json = await response.json();
    let comments = formatGetSWCommentsResponse(json);

    return comments;
  }

  public async getSWStepComments(stepID: number): Promise<IStepComment[]> {
    let response = await authPostJson(config
      .endpoints
      .sw
      .getSWStepComments
      .replace("{stepID}", stepID.toString()),
      JSON.stringify({
        StepID: stepID,
      }));

    await throwIfResponseError(response);

    let json = await response.json();
    let comments = formatGetSWStepCommentsResponse(json);

    return comments;
  }

  public async getSWUserFeedbacks(guid: string): Promise<ISWUserFeedbackDetails> {
    let endpoint = config.endpoints.sw.getSWUserFeedbacks
      .replace("{guid}", guid);
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();
    let swUserFeedbacks = formatGetSWUserFeedbacksResponse(json);

    return swUserFeedbacks;
  }

  public async getTimeRCProcessingRequestStatus(requestIds: string): Promise<ITimeRCProcessing> {

    let endpoint = config.endpoints.sw.getTimeRCProcessingRequestStatus
      .replace("{requestIds}", requestIds);
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();
    let timeRCProcessing = formatGetTimeRCProcessingResponse(json);
    return timeRCProcessing;
  }

  public async saveSWUserFeedbackAcks(swGuid: string, swUserFeedbackAcks: ISWUserFeedbackAck[]): Promise<boolean> {
    let response = await authPostJson(config
      .endpoints
      .sw
      .saveSWUserFeedbackAcks
      .replace("{guid}", swGuid),
      JSON.stringify({
        userFeedbackAcks: swUserFeedbackAcks
      }));

    await throwIfResponseError(response);
    return true;
  }

  public async deleteSWDraft(guid: string): Promise<boolean> {
    let response = await authDeleteJson(
      config.endpoints.sw.deleteSWDraft.replace("{guid}", guid),
      JSON.stringify({})
    );

    await throwIfResponseError(response);

    return true;
  }

  public async bulkDeleteSWDraft(swIds: IZipList[]): Promise<boolean> {
    let response = await authDeleteJson(
      config.endpoints.sw.bulkDeleteSWDraft,
      JSON.stringify(formatBulkDeleteSWDraft(swIds))
    );

    await throwIfResponseError(response);

    return true;
  }

  public async getPDFUrl(swId: number, trainingPDF: boolean, isExecutablePdf: boolean, isReferencePdf: boolean, isCCQPdf: boolean): Promise<string> {
    let response = await authPostJson(config
      .endpoints
      .sw
      .getPDFUrl
      .replace("{SWId}", swId.toString()),
      JSON.stringify({
        IsTraining: trainingPDF,
        Regenerate: false,
        IsExecutablePdf: isExecutablePdf,
        isReferencePdf: isReferencePdf,
        IsCCQPdf: isCCQPdf,
      }));

    await throwIfResponseError(response);

    return (await response.json()).PDFUrl;
  }

  public async downloadPDF(
    swId: number,
    pdfType: string,
    generateDefault: boolean,
    getLatestVersion: boolean
  ): Promise<IPDFDownloadResponse> {
    let response = await authPostJson(
      config.endpoints.sw.downloadPDFFile.replace("{SWId}", swId.toString()),
      JSON.stringify({
        Regenerate: false,
        PdfType: pdfType,
        GenerateDefault: generateDefault,
        GetLatestVersion: getLatestVersion,
      })
    );

    await throwIfResponseError(response);

    return {
      blob: await response.blob(),
      filename:
        response.headers.get("Content-Disposition")?.split("filename*=UTF-8''")[1] ??
        `SW_${swId}.pdf`,
    };
  }

  public async archiveRequestData(swIds: IZipList[], requestId: string): Promise<IArchiveResponse> {

    const response = await authPostJson(
      config.endpoints.sw.archiveRequestData,
      JSON.stringify(
        formatDownloadZipArchive(swIds, requestId),
      )
    );

    await throwIfResponseError(response);
    let responseObj = await response.json();
    return formatArchiveResponse(responseObj);
  }

  public async downloadZipFile(swIds: IZipList[], requestId: string): Promise<Blob> {

    const response = await authPostJson(
      config.endpoints.sw.downloadZipFile,
      JSON.stringify(
        formatDownloadZipArchive(swIds, requestId),
      )
    );

    await throwIfResponseError(response);
    return response.blob();
  }

  public async getAllSWComments(swGuid: string, swVersion: number): Promise<IAllSWComments> {
    let endpoint = config.endpoints.sw.getAllSWComments;
    endpoint = endpoint
      .replace("{swGuid}", swGuid)
      .replace("{swVersion}", swVersion.toString());
    const response = await authGetJson(endpoint);
    await throwIfResponseError(response);

    let responseObj = await response.json();

    return formatGetAllSWCommentsResponse(responseObj);
    }

  public async getRCWhereUsed(rcID: number): Promise<IGetRCMapping[]> {
    let endpoint = config.endpoints.sw.getRCMapping;
    endpoint = endpoint
      .replace("{rcID}", rcID.toString());

    const response = await authGetJson(endpoint);
    await throwIfResponseError(response);
    let responseObj = await response.json();

    return formatGetRCMappingResponse(responseObj);
  }

  public async getTimeMediaWhereUsed(_imageNumber: string): Promise<IGetTimeMediaFileMapping[]> {
    let endpoint = config.endpoints.sw.getTimeMediaFileMapping;
    endpoint = endpoint
      .replace("{imageNumber}", _imageNumber);

    const response = await authGetJson(endpoint);
    await throwIfResponseError(response);
    let responseObj = await response.json();

    return formatGetTimeMediaFilesMappingResponse(responseObj);
  }

  public async getRCGuid(rcID: number): Promise<IGetRCSW> {
    let endpoint = config.endpoints.sw.getRCGuid;
    endpoint = endpoint
      .replace("{rcID}", rcID.toString());

    const response = await authGetJson(endpoint);
    await throwIfResponseError(response);
    let responseObj = await response.json();

    return formatGetRCSWResponse(responseObj);
  }

  public async getSWRevisionHistories(swGuid: string): Promise<ISWRevisionHistory[]> {
    let endpoint = config.endpoints.sw.getSwRevisionHistories;
    endpoint = endpoint
      .replace("{swGuid}", swGuid);
    const response = await authGetJson(endpoint);
    await throwIfResponseError(response);

    let responseObj = await response.json();

    return formatGetSWRevisionHistoriesResponse(responseObj);
  }

  public async resolveSWComment(commentId: number, swId: number, resolved: boolean): Promise<IComment[]> {
    const response = await authPostJson(config
      .endpoints
      .sw
      .resolveSWComment
      .replace("{commentID}", commentId.toString()),
      JSON.stringify({
        Id: commentId,
        SWId: swId,
        Resolved: resolved,
      }));

    await throwIfResponseError(response);
    let json = await response.json();
    return formatGetSWCommentsResponse(json);
  }

  public async getConfidentialEnabledUsers(
    swId: number
  ): Promise<IConfidentialSWUser[]> {
    const endpoint = config.endpoints.sw.getConfidentialEnabledUsers.replace(
      "{swId}",
      swId.toString()
    );

    const response = await authGetJson(endpoint);
    await throwIfResponseError(response);
    const responseObj = await response.json();
    return formatGetConfidentialEnabledUsersResponse(responseObj);
  }

  public async modifyConfidentialEnabledUsers(
    swId: number,
    email: string,
    role: ConfidentialAccessRoles,
    addOrRemove: "add" | "remove"
  ): Promise<void> {
    const endpoint = config.endpoints.sw.getConfidentialEnabledUsers.replace(
      "{swId}",
      swId.toString()
    );

    const response = await authPostJson(
      endpoint,
      JSON.stringify({
        Email: email,
        IsRemoval: addOrRemove !== "add",
        Role: role,
      })
    );

    await throwIfResponseError(response);
  }

  public async getConnectedSWIs(swGuid: string, swVersion: number): Promise<IConnectedSWIData> {
    let endpoint = config
      .endpoints
      .sw
      .getConnectedSWIs
      .replace("{swGuid}", swGuid)
      .replace("{swVersion}", swVersion.toString());

    const response = await authGetJson(endpoint);
    await throwIfResponseError(response);

    let json = await response.json();

    return formatGetConnectedSWIsResponse(json);
  }

  public async addConnectedSWI(connectedSWI: IAddConnectedSWIPayload): Promise<true> {
    const response = await authPostJson(config
      .endpoints
      .sw
      .addConnectedSWI
      .replace("{swGuid}", connectedSWI.swGuid)
      .replace("{swVersion}", connectedSWI.swVersion.toString()),
      JSON.stringify(connectedSWI));

    await throwIfResponseError(response);
    return true;
  }

  public async deleteConnectedSWI(payload: IDeleteConnectedSWIPayload): Promise<true> {
    const response = await authDeleteJson(config
      .endpoints
      .sw
      .deleteConnectedSWI
      .replace("{swGuid}", payload.swGuid)
      .replace("{swVersion}", payload.swVersion.toString())
      .replace("{connectedSwiId}", payload.id.toString()),
      null);
    await throwIfResponseError(response);
    return true;
  }

  public async uploadConnectedSWIs(
    swGuid: string,
    swVersion: number,
    file: File
  ): Promise<IUploadConnectedSWIsResponse> {
    let formData = new FormData();
    formData.append("File", file);

    let response = await authPostFormData(
      config.endpoints.sw.uploadConnectedSWIs
        .replace("{swGuid}", swGuid)
        .replace("{swVersion}", swVersion.toString()),
      formData
    );

    await throwIfResponseError(response);

    return formatUploadConnectedSWIsResponse(await response.json());
  }

  public async getConnectedSWIsByChildGuid(
    searchTerm: string | undefined
  ): Promise<IMasterDataItem[]> {
    const encodedSearchTerm = encodeURIComponent(searchTerm ?? "");

    const response = await authGetJson(
      config.endpoints.sw.getConnectedSWIsByChildGuid +
      `?searchTerm=${encodedSearchTerm}`
    );

    await throwIfResponseError(response);
    const json = await response.json();

    return formatGetConnectedSWIsByChildGuidResponse(json);
  }

  public async getSWNoticeSummary(swGuid: string, swVersion: number): Promise<ISWNoticeSummary> {
      let endpoint = config.endpoints.sw.getSWNoticeSummary;
      endpoint = endpoint
          .replace("{swGuid}", swGuid)
          .replace("{swVersion}", swVersion.toString());
      const response = await authGetJson(endpoint);
      await throwIfResponseError(response);

      let json =await response.json();
      return formatGetSWNoticeSummaryResponse(json);
  }

  public async copySWAttachmentsToSWDraft(
    copySWAttachmentsToSWDraft: ICopySWAttachmentsToSWDraft): Promise<ICopiedSWAttachment[]> {
    let response = await authPostJson(
      config.endpoints.sw.copySWAttachmentsToSWDraft,
      JSON.stringify(copySWAttachmentsToSWDraft)
    );
    await throwIfResponseError(response);
    const json = await response.json();

    return formatCopySWAttachmentsToSWDraft(json);
  }
}

export default new SWApi();
