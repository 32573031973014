import React, { useEffect, useRef, useState } from "react";
import SWRow from "./SWRow";
import SWSort from "./SWSort";
import "./SWSearch.scoped.scss";
import useSelector from "store/useSelector";
import Banner, { BannerType } from "components/common/Banner";
import CreateDraftModal from "./CreateDraftModal";
import CreateLCLModal from "./CreateLCLModal";
import ArchiveModal from "./ArchiveModal";
import RCMappingModal from "./RCMappingModal";
import SWUserFeedbacksModal from "./SWUserFeedbacksModal";
import { Redirect, useLocation } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import { useDispatch } from "react-redux";
import Modal from "components/common/Modal";

import {
    setSort,
    setCreateDraftData,
    setArchiveData,
    getData,
    resetAllExceptFilters,
    setDeleteDraftData,
    addFilterItem,
    removeFilterItem,
    applyFilter,
    clearFilter,
    setStringFilter,
    setSearchText,
    loadSWUserFeedbacks,
    setCreateLCLData,
    getDownloadZip,
    getArchiveRequest,
    setNewIndex,
    confirmBulkDeleteDraft,
    setRCType,
    loadArchivalSWMFGConfiguration,
    setConnectedSWArchivalPopupData,
    saveConnectedSWArchivalData,
} from "store/swList/swListActions";

import DeleteDraftModal from "./DeleteDraftModal";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import SearchBar from "components/common/SearchBar";
import FlowLayout from "components/layout/FlowLayout";
import { clearSearchedMasterDataItems } from "store/masterData/masterDataActions";
import ModalSpinner from "components/common/ModalSpinner";
import { SWSortFields, SWTypes } from "interfaces/sw/SWInterfaces";
import { SortDirection } from "interfaces/misc/miscInterfaces";
import { showErrorToast } from "store/toast/toastActions";

const SWSearch: React.FC = () => {
    const {
        swListData: {
            loadOperation,
            swList,
            totalCount,
            skipIndex,
        },
        createDraftData,
        createLCLData,
        archiveData,
        filterData,
        deleteDraftData,
        userFeedbacksData,
        rcMappingData,
        swListForZip: {
            swListZip,
        },
        setDownloadZipData,
        setArchiveRequestData,
        connectedSWArchivalPopupData
    } = useSelector(store => store.swList);

    const dispatch = useDispatch();
    const wasCreateLCLSuccessful = createLCLData.createLCLOperation?.wasSuccessful;
    const wasCreateDraftSuccessful = createDraftData.createDraftOperation?.wasSuccessful;
    const wasArchiveSuccessful = archiveData.archiveOperation?.wasSuccessful;
    const wasDeleteDraftSuccessful = deleteDraftData.deleteOperation?.wasSuccessful;
    const location = useLocation();
    let sortDirSelectedCol = filterData.sortDir;
    let sortBySelectedCol = filterData.sortBy;
    const LENGTH = totalCount;
    const LIMIT = 100;
    const inputBoxRef = useRef<HTMLInputElement | null>(null);

    
    useEffect(() => {
        dispatch(
        loadArchivalSWMFGConfiguration()
        )
    });

    const loadMore = () => {
        const newIndex = skipIndex !== undefined ? (skipIndex + LIMIT) : 0;
        const newShowMore = newIndex < (LENGTH - 1);
        if (newShowMore) {
            dispatch(setNewIndex(newIndex));
        }
    }

    useEffect(() => {
        if (inputBoxRef.current){
            inputBoxRef.current.focus(); // set focus to search input box after rendering results
        }
    }, [swList]);

    useEffect(() => {
        if (location.search.indexOf("?showFeedback=") >= 0) {
            let swGuid = location.search.substr(
                location.search.lastIndexOf("=") + 1);
            dispatch(loadSWUserFeedbacks({
                swGuid
            }));
        }
    }, [location, dispatch,]);

    const sortByColumn = (name: string, sortBy: SWSortFields) => {
        let sortDir;
        if (sortBySelectedCol !== sortBy) {
            sortDir = SortDirection.Asc;
        }
        else {
            if (sortDirSelectedCol === SortDirection.Asc) {
                sortDir = SortDirection.Desc;
            }
            else {
                sortDir = SortDirection.Asc;
            }
        }
        dispatch(
            setSort({
                sortBy: sortBy,
                sortDir: sortDir,
            })
        )
    }

    const onAddItem = (item: IMasterDataItem, name: MetaDataNames) => {
        dispatch(
            addFilterItem({
                metaDataName: name,
                item,
            })
        );
    }

    const onRemoveItem = (item: IMasterDataItem, name: MetaDataNames) => {
        dispatch(
            removeFilterItem({
                metaDataName: name,
                item,
            })
        );
    }

    const setSWStringFilter = (name: "maintenanceTaskIds", value: string) => {
        dispatch(setStringFilter({
            attributeName: name,
            value: value,
        }));
    }

    const applyDipatchFilter = () => {
        dispatch(applyFilter({ isRCFilter: false, onlyTask: false, isSourceSystemTIME: false, searchSource: "SearchSW" }));
        dispatch(clearSearchedMasterDataItems());
    }

    useEffect(() => {
        if (wasCreateDraftSuccessful) {
            dispatch(
              setCreateDraftData({
                createDraftOperation: undefined,
                guid: "",
                version: -1,
                duplicate: false,
                isWaitingForConfirm: false,
                thumbsDownCount: 0,
                thumbsUpCount: 0,
                swType: SWTypes.Unspecified,
                newOwningOrgId: undefined,
                newOwningPlantId: undefined,
                updateGemsRevision: undefined,
              })
            );
        }
    }, [wasCreateDraftSuccessful, dispatch]);

    useEffect(() => {
        if (wasCreateLCLSuccessful) {
            dispatch(setCreateLCLData({
                createLCLOperation: undefined,
                guid: "",
                version: -1,
                isWaitingForConfirm: false,
            }));
        }
    }, [wasCreateLCLSuccessful, dispatch]);

    useEffect(() => {
        if (wasArchiveSuccessful) {
            dispatch(setArchiveData({
                archiveOperation: undefined,
                guid: "",
                isWaitingForConfirm: false,
            }));

            dispatch(getData());
        }
    }, [wasArchiveSuccessful, dispatch]);

    useEffect(() => {
        if (wasDeleteDraftSuccessful) {
            dispatch(setDeleteDraftData({
                deleteOperation: undefined,
                currentEditor: undefined,
                currentEditorLockedDate: undefined,
                guid: "",
                isWaitingForConfirm: false,
            }));

            dispatch(getData());
        }
    }, [wasDeleteDraftSuccessful, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetAllExceptFilters());
        };
    }, [dispatch]);

    const [selectedOptionArchival, setSelectedOptionArchival] = useState<string>('');

    const handleChangeArchival = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setSelectedOptionArchival(value);
    };

    const ArchiveConnectedSWWithNewParent = () =>{
        var selectedConnectedMaterialGroupGC = connectedSWArchivalPopupData.connectedSWIs
            .find(f => f.id.toString() === selectedOptionArchival);
        if (selectedConnectedMaterialGroupGC)
        {
            dispatch(saveConnectedSWArchivalData(
                {
                    swGuid : connectedSWArchivalPopupData.swGuid,
                    swVersion : connectedSWArchivalPopupData.swVersion,
                    newOwningPlantId: selectedConnectedMaterialGroupGC.plantCode,
                    newSwMaterial : selectedConnectedMaterialGroupGC.materialCode,
                    newSWGroup : selectedConnectedMaterialGroupGC.group,
                    newSWGroupCounter : selectedConnectedMaterialGroupGC.groupCounter,
                    connectedSWId: selectedConnectedMaterialGroupGC.id,
                }
            ));
        } 
    }

    let onSetSearchRef = useRef((value: string) => dispatch(setSearchText(value)));

    let component: JSX.Element | undefined;

    if (loadOperation?.errorMessage) {
        component = (
            <Banner
                type={BannerType.Error}
            >
                {loadOperation.errorMessage}
            </Banner>
        );
    } else if (!swList.length) {
        component = (
            <label>No StandardWorks found.</label>
        );
    } else {
        component = (
            <>
                <div className="row header">
                    <div className="cell sw-checkbox">
                        <span>Select</span>
                    </div>
                    <div className="cell sw-name swname"
                        onClick={() => sortByColumn(SWSortFields.Title, SWSortFields.Title)}
                    >
                        <span>Standard Work Name</span>
                        <SWSort
                            colName="Title"
                        />
                    </div>
                    <div className="cell rating"
                        onClick={() => sortByColumn(SWSortFields.Rating, SWSortFields.Rating)}
                    >
                        <span>Rating</span>
                        <SWSort
                            colName="Rating"
                        />
                    </div>
                    <div className="cell execution-count"
                        onClick={() => sortByColumn(SWSortFields.ExecutionCount, SWSortFields.ExecutionCount)}
                    >
                        <span>Execution Count</span>
                        <SWSort
                            colName="ExecutionCount"
                        />
                    </div>
                    <div className="cell comments"
                        onClick={() => sortByColumn(SWSortFields.Comments, SWSortFields.Comments)}
                    >
                        <span>Comments</span>
                        <SWSort
                            colName="Comments"
                        />
                    </div>
                    <div className="cell pub"
                        onClick={() => sortByColumn(SWSortFields.LatestPublishedCreatedOn, SWSortFields.LatestPublishedCreatedOn)}
                    >
                        <span>Last Published</span>
                        <SWSort
                            colName="LatestPublishedCreatedOn"
                        />
                    </div>
                    <div className="cell status"
                        onClick={() => sortByColumn(SWSortFields.ModifiedOn, SWSortFields.ModifiedOn)}
                    >
                        <span>Status</span>
                        <SWSort
                            colName="ModifiedOn"
                        />
                    </div>
                    <div className="cell buttons">

                    </div>
                </div>
                <div className="list-body">
                    {swList.map(sw =>
                        <SWRow
                            sw={sw}
                            key={sw.id}
                            checkedSW={swListZip.findIndex(x => x.swid === sw.id) > -1}
                        />
                    )}
                    
                    { connectedSWArchivalPopupData.isConnectedSWArchivalPopupOpen &&
                        <Modal
                            header="Archival of Parent Standard Work containing Connected Child SWIs"
                            isOpen={true}
                            width={"40%"}
                            maxHeight={"50%"}
                            controls={(
                                <>
                                  <button
                                    className="primary-button"
                                    onClick={ () => ArchiveConnectedSWWithNewParent() }
                                  >
                                    Archive
                                  </button>
                                  <button
                                    className="secondary-button"
                                    onClick={ () =>
                                        dispatch(setConnectedSWArchivalPopupData({
                                        swGuid: '',
                                        swVersion: 0,
                                        isConnectedSWArchivalPopupOpen: false,
                                        connectedSWIs: [],
                                        saveConnectedSWArchiveOperation: undefined
                                        }))
                                    }
                                  >
                                    Cancel
                                  </button>
                                </>
                              )}
                        >
                            <div>
                                <div>
                                    <label>Select Material, Group and Group Counter for creating new draft of same SW as Parent after Archival</label>
                                </div>
                                <div>
                                    <label>(Plant Code: {connectedSWArchivalPopupData.connectedSWIs[0].plantCode})</label>
                                </div>
                                <div>
                                   &nbsp;
                                </div>
                                <div>
                                    <select value={selectedOptionArchival} onChange={handleChangeArchival}>
                                    <option key="Material_Group_GC" value="Material_Group_GC">Material - Group - GroupCounter </option>
                                    {   connectedSWArchivalPopupData.connectedSWIs.filter(f => f.dupeGuid).map(r=>
                                            <option key={r.id} value={r.id}>{r.materialCode} - {r.group} - {r.groupCounter}</option>              
                                        )
                                    }
                                    </select>
                                </div>
                                <div>
                                    <span className="note">
                                        Note: The Archival changes of Standard works once done cannot be reversed.
                                    </span>
                                </div>
                            </div>
                        </Modal>                       
                          
                    }
                    {connectedSWArchivalPopupData.saveConnectedSWArchiveOperation?.isWorking &&
                        <ModalSpinner />
                    }
                    {connectedSWArchivalPopupData.saveConnectedSWArchiveOperation?.wasSuccessful &&
                         (<Redirect to={Routes.EditStandardWork.replace(":guid", connectedSWArchivalPopupData.swGuid).replace(":version", "0")}></Redirect>)
                    }
                    
                </div>
            </>
        );
    }

    let createDraftComponent: JSX.Element | undefined;

    if (createDraftData.isWaitingForConfirm) {
        createDraftComponent = (
            <CreateDraftModal {...createDraftData} />
        );
    } else if (createDraftData.createDraftOperation?.wasSuccessful) {
        createDraftComponent = (
            <Redirect
                to={Routes.EditStandardWork.replace(":guid", createDraftData.guid).replace(":version", "0")}
            />
        );
    }

    let createLCLComponent: JSX.Element | undefined;

    if (createLCLData.isWaitingForConfirm) {
        createLCLComponent = (
            <CreateLCLModal
                swGuid={createLCLData.guid}
                swVersion={createLCLData.version}
            />
        );
    } else if (createLCLData.createLCLOperation?.wasSuccessful) {
        createLCLComponent = (
            <Redirect
                to={Routes.EditStandardWork.replace(":guid", createLCLData.guid).replace(":version", "0")}
            />
        );
    }

    let archiveComponent: JSX.Element | undefined;

    if (archiveData.isWaitingForConfirm) {
        archiveComponent = (
            <ArchiveModal
                swGuid={archiveData.guid}
            />
        );
    }

    let rcMappingComponent: JSX.Element | undefined;
    if (rcMappingData.loadRCMappingData?.wasSuccessful) {
        rcMappingComponent = (
            <RCMappingModal />
        )
    }

    let deleteDraftComponent: JSX.Element | undefined;

    if (deleteDraftData.isWaitingForConfirm) {
        deleteDraftComponent = (
            <DeleteDraftModal
                swGuid={deleteDraftData.guid}
                currentEditor={deleteDraftData.currentEditor}
                currentEditorLockedDate={deleteDraftData.currentEditorLockedDate}
            />
        );
    }

    let userFeedbacksComponent: JSX.Element | undefined;

    if (userFeedbacksData.loadUserFeedbacksOperation?.isWorking) {
        userFeedbacksComponent = (
            <ModalSpinner />
        );
    } else if (userFeedbacksData.loadUserFeedbacksOperation?.errorMessage) {
        userFeedbacksComponent = (
            <Banner
                type={BannerType.Error}
            >
                {userFeedbacksData.loadUserFeedbacksOperation.errorMessage}
            </Banner>
        );
    }
    else if (userFeedbacksData.loadUserFeedbacksOperation?.wasSuccessful
        && userFeedbacksData.swUserFeedbackDetails.userFeedbacks.length > 0) {
        userFeedbacksComponent = (
            <SWUserFeedbacksModal />
        );
    }
    const downloadZipFile = () => {
        if (setDownloadZipData.length > 0) {
            dispatch(showErrorToast("Already one request is going on so please wait..."));
            return;
        }

        if (swListZip.length === 0) {
            dispatch(showErrorToast("Please select atleast one standard work."));
            return;
        }

        if (window.confirm("Are you sure you want to continue?")) {
            dispatch(getDownloadZip(
                swListZip,
            ));
        }
    }

    const archiveRequest = () => {
        if (setArchiveRequestData.length > 0) {
            dispatch(showErrorToast("Already one request is going on so please wait..."));
            return;
        }

        if (swListZip.length === 0) {
            dispatch(showErrorToast("Please select atleast one standard work."));
            return;
        }

        if (window.confirm("Are you sure you want to continue?")) {
            dispatch(getArchiveRequest(
                swListZip,
            ));
        }
    }

    const bulkDelete = () => {
        if (swListZip.length === 0) {
            dispatch(showErrorToast("Please select atleast one standard work."));
            return;
        }

        if (window.confirm("Are you sure you want to delete the selected standard works?")) {
            dispatch(confirmBulkDeleteDraft(
                swListZip,
            ));
        }
    }

    return (
        <>
            <FlowLayout
                header={
                    <SearchBar
                        swFilterFields={filterData.filterFields}
                        setSearchText={onSetSearchRef.current}
                        onAddSWItem={onAddItem}
                        onRemoveSWItem={onRemoveItem}
                        applyFilter={applyDipatchFilter}
                        clearFilter={() => dispatch(clearFilter())}
                        setSWStringFilter={setSWStringFilter}
                        extraFilterCount={0}
                        isDisabled={loadOperation?.isWorking === true}
                        showStatusFilter={true}
                        placeholder="Search by title, description, or guid..."
                        downloadZipFile={downloadZipFile}
                        getArchiveRequest={archiveRequest}
                        bulkDelete={bulkDelete}
                        showZipArchiveButton={true}
                        setRCType={(value) => dispatch(setRCType(value))}
                        inputBoxRef={inputBoxRef}
                    />
                }
            >
                {component}
                {createDraftComponent}
                {createLCLComponent}
                {archiveComponent}
                {deleteDraftComponent}
                {userFeedbacksComponent}
                {rcMappingComponent}
            </FlowLayout>
            {swList.length > 0 && (swList.length !== totalCount) && <button className="primary-button" onClick={loadMore}> Load More </button>}
        </>
    );
}

export default SWSearch;